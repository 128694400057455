<template>
  <div class="container-fluid" >
    <div class="row">
      <!-- Tab cards -->
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body">
            <p class="alert alert-primary">
              <i class="far fa-info me-2"></i>
              Create and share custom T&C documents for your clinic, or choose from one of our pre-loaded Swandoola T&C Templates to share with your clients.
            </p>
            <terms-list :user="user"></terms-list>
            <templates-list></templates-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import PreviewModal from "./terms-partials/PreviewModal";
  import TemplatesList from "./terms-partials/TemplatesList";
  import TermsList from "./terms-partials/TermsList";

  export default {
      props: [],
      data() {
          return {
          };
      },
      methods: {
      },
      filters: {

      },
      computed: {
        user() {
          return this.$store.getters['auth/user'];
        },
        path() {
          return this.$router.currentRoute.fullPath;
        },
      },
      components: {
          TermsList,
          TemplatesList,
          PreviewModal,

      }
  };
</script>
<style>
  .dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  }
</style>
