<template>
  <div>
    <div class="card border-0 bg-light shadow-sm mb-4">
      <div class="card-body">
        <h5 class="mb-3">Swandoola T&C Templates</h5>
        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="row mb-3" v-if="templates.length > 0">
            <div class="col">
              <div class="card">
                <table class="table rounded table-hover bg-white mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style="border-top: 0px">Document</th>
                      <th scope="col" style="border-top: 0px">Version</th>
                      <th scope="col" style="border-top: 0px">Created</th>
                      <th scope="col" style="border-top: 0px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="template in templates">
                      <td>
                        <i class="fas fa-handshake me-2 text-primary"></i>
                        {{ template.title }}
                      </td>
                      <td>{{ template.version }}</td>
                      <td>{{ template.created_at | formatDate }}</td>
                      <td class="text-nowrap">
                        <button
                          class="btn btn-light btn-sm ms-1"
                          @click="showCloneTemplate(template)"
                          v-tooltip.bottom="
                            'Create a copy of this template for editing'
                          "
                        >
                          <i class="fa fa-copy"></i>
                        </button>
                        <button
                          class="btn btn-light btn-sm ms-1"
                          @click="showPreviewTemplate(template)"
                          v-tooltip.bottom="'Preview'"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <preview-modal
      name="previewTemplateModal"
      :preview-term="previewTemplate"
    ></preview-modal>

    <clone-modal :template-to-clone="templateToClone"></clone-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import PreviewModal from "./PreviewModal";
import CloneModal from "./CloneModal";

export default {
  props: [],
  data() {
    return {
      loading: true,
      templates: [],
      expandedTemplate: null,
      previewTemplate: null,
      templateToClone: null,
    };
  },
  methods: {
    fetchTemplates() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/terms/fetch-templates")
        .then(({ data }) => {
          this.templates = data;
          this.loading = false;
        });
    },
    showPreviewTemplate(template) {
      this.previewTemplate = template;
      $("#previewTemplateModal").modal("show");
    },
    showCloneTemplate(template) {
      this.templateToClone = template;
      $("#cloneTemplateModal").modal("show");
    },
    updateShare(term) {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/settings/terms/" + term.id, {
          share_with_all: term.share_with_all,
        })
        .then(({ data }) => {
          this.expandedTemplate = null;
          this.$EventBus.$emit("alert", data);
        });
    },
  },
  mounted() {
    this.fetchTemplates();
  },
  created() {},
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  components: {
    CloneModal,
    PreviewModal,
    VueEditor,
  },
};
</script>
<style></style>