<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" id="cloneTemplateModal">
      <div class="modal-dialog modal modal-dialog-centered" role="document">
        <div class="modal-content bg-white">
          <div class="modal-body">
            <form @submit.prevent="cloneTemplate">
              <div class="row mb-3">
                <div class="col">
                  <label>Enter a name for your document</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="name"
                    placeholder="Enter a name..."
                    required
                  />
                </div>
              </div>
              <div class="row">
                <div class="col text-end">
                  <button class="btn btn-primary">
                    <i class="far fa-copy me-1"></i>
                    Clone Template
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["templateToClone"],
  data() {
    return {
      name: "",
    };
  },
  methods: {
    cloneTemplate() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/terms/" +
            this.templateToClone.id +
            "/clone",
          {
            name: this.name,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("clonedTemplate");
          $("#cloneTemplateModal").modal("hide");
        });
    },
  },
  mounted() {},
  components: {
    VueEditor,
  },
};
</script>