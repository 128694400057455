<template>
  <div>
    <div
      class="card border-0 bg-light shadow-sm mb-4"
      v-if="!showNewTerms && !showEditTerm"
    >
      <div class="card-body">
        <h5 class="mb-3">My Clinic T&Cs</h5>
        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="row" v-if="terms.length > 0">
            <div class="col-12">
              <div class="mb-4">
                <input
                  type="text"
                  class="form-control w-50"
                  placeholder="Search terms"
                  v-model="searchText"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="card mb-3">
                <table class="table rounded table-hover bg-white mb-0">
                  <thead>
                    <tr>
                      <th scope="col" style="border-top: 0px">Document</th>
                      <th scope="col" style="border-top: 0px">Version</th>
                      <th scope="col" style="border-top: 0px">Status</th>
                      <th
                        scope="col"
                        style="border-top: 0px"
                        class="text-nowrap"
                      >
                        <span
                          v-tooltip:bottom="
                            'Default terms are automatically shared with all clients'
                          "
                        >
                          Default
                          <i class="far fa-info-circle text-primary ms-2"></i>
                        </span>
                      </th>
                      <th scope="col" style="border-top: 0px">Created</th>
                      <th scope="col" style="border-top: 0px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="term in filteredTerms">
                      <td>
                        <i class="fas fa-handshake me-2 text-primary"></i>
                        {{ term.title }}
                      </td>
                      <td>{{ term.version }}</td>
                      <td class="text-capitalize">
                        {{ term.status }}
                      </td>
                      <td class="text-center">
                        <i
                          class="text-primary far fa-check-circle"
                          v-if="term.share_with_all"
                        ></i>
                      </td>
                      <td>{{ term.created_at | formatDate }}</td>
                      <td class="text-nowrap">
                        <button
                          v-if="term.status === 'draft'"
                          class="btn btn-light btn-sm ms-1"
                          @click="editClinicTerm(term)"
                          v-tooltip:bottom="'Edit'"
                        >
                          <i class="fa fa-pencil"></i>
                        </button>
                        <button
                          v-if="term.status !== 'draft'"
                          class="btn btn-light btn-sm ms-1"
                          @click="createNewVersion(term)"
                          v-tooltip:bottom="'Create New Version'"
                        >
                          <i class="fa fa-code-branch"></i>
                        </button>
                        <button
                          class="btn btn-light btn-sm ms-1"
                          @click="previewTerm(term)"
                          v-tooltip:bottom="'Preview'"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                        <button
                          v-if="term.status === 'published'"
                          class="btn btn-light btn-sm ms-1"
                          @click="expandedTerm = term"
                          v-tooltip:bottom="'Configure Sharing / Default Terms'"
                        >
                          <i class="fa fa-cogs"></i>
                        </button>
                        <button
                          v-if="term.status === 'draft'"
                          class="btn btn-danger btn-sm ms-1"
                          @click="deleteTerms(term)"
                          v-tooltip:bottom="'Delete'"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                        <button
                          v-if="term.status === 'published'"
                          class="btn btn-outline-danger btn-sm ms-1"
                          @click="discontinueTerm(term)"
                          v-tooltip:bottom="'Discontinue Terms'"
                        >
                          <i class="fa fa-ban"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button
                class="btn btn-sm btn-primary mb-1 float-end"
                @click="newTermsClick"
              >
                <i class="fa fa-plus me-1"></i>
                New Clinic T&C Document
              </button>
            </div>
          </div>
          <div class="row mb-2" v-else>
            <div class="col-8 mx-auto text-center">
              <div class="card">
                <div class="card-body">
                  <i class="fas fa-info-circle text-primary fa-4x"></i>
                  <p>
                    No clinic T&Cs found. Create a new clinic T&C document or
                    use our pre-loaded Swandoola T&C templates.
                  </p>
                  <button
                    class="btn btn-sm btn-primary"
                    @click="newTermsClick"
                  >
                    <i class="fa fa-plus me-1"></i>
                    New Clinic T&C Document
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <terms-builder
      v-if="showNewTerms || showEditTerm"
      :user="user"
      :clinic-terms="editTerm"
      @saved="termsUpdated"
      @cancel="cancelBuilder"
    ></terms-builder>
    <preview-modal
      name="previewTermsModal"
      :preview-term="previewTerms"
    ></preview-modal>

    <share-modal
      :selected-term="expandedTerm"
      @close="cancelShare"
      @updated="fetchTerms"
    ></share-modal>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import PreviewModal from "./PreviewModal";
import TermsBuilder from "./TermsBuilder";
import ShareModal from "./ShareModal";

export default {
  props: ["user"],
  data() {
    return {
      loading: true,
      busySaving: false,
      terms: [],
      expandedTerm: null,
      previewTerms: null,
      showNewTerms: false,
      showEditTerm: false,
      editTerm: null,
      searchText: '',
    };
  },
  computed: {
    filteredTerms() {
      if(!this.searchText || this.searchText.length < 1) {
        return this.terms;
      }
      return this.terms.filter((term) => {
        return (
          term.title.toLowerCase().includes(this.searchText.toLowerCase()) ||
          term.body.toLowerCase().includes(this.searchText.toLowerCase())
        );
      });
    }
  },
  methods: {
    newTermsClick() {
      this.showNewTerms = true;
      this.editTerm = null;
    },
    fetchTerms() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/settings/terms/fetch-terms")
        .then(({ data }) => {
          this.terms = data;
          this.loading = false;
        });
    },
    previewTerm(terms) {
      this.previewTerms = terms;
      $("#previewTermsModal").modal("show");
    },
    createNewVersion(term) {
      this.busySaving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/terms/" +
            term.id +
            "/new-version"
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.fetchTerms();
          this.busySaving = false;
        });
    },
    deleteTerms(term) {
      var confirmed = confirm(
        "Are you sure you wish to delete this document? This can not be undone."
      );
      if (confirmed) {
        this.$axios
          .delete(process.env.VUE_APP_API_URL + "/settings/terms/" + term.id)
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchTerms();
          });
      }
    },
    discontinueTerm(term) {
      var confirmed = confirm(
        "Are you sure you wish to discontinue this document? You will no longer be able to issue or modify these terms. This can not be undone."
      );
      if (confirmed) {
        this.busySaving = true;
        term.status = "discontinued";
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/settings/terms/" + term.id,
            term
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.fetchTerms();
            this.busySaving = false;
          });
      }
    },
    cancelBuilder() {
      this.showNewTerms = false;
      this.showEditTerm = false;
    },
    termsUpdated() {
      this.showNewTerms = false;
      this.showEditTerm = false;
      this.fetchTerms();
    },
    editClinicTerm(term) {
      this.editTerm = Object.assign({}, term);
      this.showEditTerm = true;
      this.showNewTerms = false;
    },
    cancelShare() {
      this.expandedTerm = null;
    },
  },
  mounted() {
    this.fetchTerms();
  },
  created() {
    this.$EventBus.$on("clonedTemplate", (data) => {
      this.fetchTerms();
    });
  },
  filters: {
    formatDate(date) {
      return moment(date).format("L");
    },
  },
  components: {
    ShareModal,
    TermsBuilder,
    PreviewModal,
    VueEditor,
  },
};
</script>