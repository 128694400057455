<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" :id="name">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content bg-white" v-if="previewTerm">
          <div class="modal-header">
            <h5 class="modal-title">
              Preview Clinic Terms: {{ previewTerm.title }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="previewPage === 'body'">
              <div class="row">
                <div class="col">
                  <div class="card bg-light">
                    <div
                      class="card-body"
                      style="overflow-y: auto; max-height: 450px"
                    >
                      <h5>Terms</h5>
                      <hr />
                      <div v-html="previewTerm.body"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="previewPage === 'health'">
              <div class="card bg-light">
                <div
                  class="card-body"
                  style="overflow-y: auto; max-height: 450px"
                >
                  <h5>Health Care Preferences</h5>
                  <hr />
                  <div class="row mb-3" v-for="consent in healthConsents">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :id="`consent${consent.id}`" />
                        <label class="form-check-label" :for="`consent${consent.id}`">
                          <span v-if="typeof consent === 'string'">
                            {{ consent }}
                          </span>
                          <span v-else>
                            {{ consent.title }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="previewPage === 'education'">
              <div class="card bg-light">
                <div
                  class="card-body"
                  style="overflow-y: auto; max-height: 450px"
                >
                  <h5>Educational Preferences</h5>
                  <hr />
                  <div class="row mb-3" v-for="consent in educationConsents">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :id="`consent${consent.id}`" />
                        <label class="form-check-label" :for="`consent${consent.id}`">
                          <span v-if="typeof consent === 'string'">
                            {{ consent }}
                          </span>
                          <span v-else>
                            {{ consent.title }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="previewPage === 'marketing'">
              <div class="card bg-light">
                <div
                  class="card-body"
                  style="overflow-y: auto; max-height: 450px"
                >
                  <h5>Marketing Preferences</h5>
                  <hr />
                  <div class="row mb-3" v-for="consent in marketingConsents">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :id="`consent${consent.id}`" />
                        <label class="form-check-label" :for="`consent${consent.id}`">
                          <span v-if="typeof consent === 'string'">
                            {{ consent }}
                          </span>
                          <span v-else>
                            {{ consent.title }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="previewPage === 'other'">
              <div class="card bg-light">
                <div
                  class="card-body"
                  style="overflow-y: auto; max-height: 450px"
                >
                  <h5>Consent</h5>
                  <hr />
                  <div class="row mb-3" v-for="consent in otherConsents">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" :id="`consent${consent.id}`" />
                        <label class="form-check-label" :for="`consent${consent.id}`">
                          <span v-if="typeof consent === 'string'">
                            {{ consent }}
                          </span>
                          <span v-else>
                            {{ consent.title }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="previewPage === lastPage">
              <div class="row mt-3">
                <div class="col text-end">
                  <button class="btn btn-light me-2" @click.prevent="goBack">
                    <i class="far fa-arrow-left me-1"></i>
                    Back
                  </button>
                  <button class="btn btn-primary">
                    <i class="far fa-save me-1"></i>
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="row mt-3">
                <div class="col text-end">
                  <button
                    class="btn btn-light me-2"
                    v-if="previewPage !== 'body'"
                    @click.prevent="goBack"
                  >
                    <i class="far fa-arrow-left me-1"></i>
                    Back
                  </button>
                  <button class="btn btn-primary" @click.prevent="goForward">
                    <i class="far fa-arrow-right me-1"></i>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm me-auto"
              data-bs-dismiss="modal" data-dismiss="modal"
              aria-label="Close"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  props: ["name", "previewTerm"],
  data() {
    return {
      previewPage: "body",
    };
  },
  methods: {
    goBack() {
      if (this.previewPage === "other") {
        if (this.marketingConsents.length > 0) {
          this.previewPage = "marketing";
        } else if (this.educationConsents.length > 0) {
          this.previewPage = "education";
        } else if (this.healthConsents.length > 0) {
          this.previewPage = "health";
        } else {
          this.previewPage = "body";
        }
      } else if (this.previewPage === "marketing") {
        if (this.educationConsents.length > 0) {
          this.previewPage = "education";
        } else if (this.healthConsents.length > 0) {
          this.previewPage = "health";
        } else {
          this.previewPage = "body";
        }
      } else if (this.previewPage === "education") {
        if (this.healthConsents.length > 0) {
          this.previewPage = "health";
        } else {
          this.previewPage = "body";
        }
      } else if (this.previewPage === "health") {
        this.previewPage = "body";
      }
    },
    goForward() {
      if (this.previewPage === "body") {
        if (this.healthConsents.length > 0) {
          this.previewPage = "health";
        } else if (this.educationConsents.length > 0) {
          this.previewPage = "education";
        } else if (this.marketingConsents.length > 0) {
          this.previewPage = "marketing";
        } else if (this.otherConsents.length > 0) {
          this.previewPage = "other";
        }
      } else if (this.previewPage === "health") {
        if (this.educationConsents.length > 0) {
          this.previewPage = "education";
        } else if (this.marketingConsents.length > 0) {
          this.previewPage = "marketing";
        } else if (this.otherConsents.length > 0) {
          this.previewPage = "other";
        }
      } else if (this.previewPage === "education") {
        if (this.marketingConsents.length > 0) {
          this.previewPage = "marketing";
        } else if (this.otherConsents.length > 0) {
          this.previewPage = "other";
        }
      } else if (this.previewPage === "marketing") {
        if (this.otherConsents.length > 0) {
          this.previewPage = "other";
        }
      }
    },
  },
  mounted() {
    $("#" + this.name).on("hide.bs.modal", (e) => {
      this.previewPage = "body";
    });
  },
  computed: {
    healthConsents() {
      if (!this.previewTerm || !this.previewTerm.consents) {
        return;
      }
      return this.previewTerm.consents.filter(function (c) {
        return c.type === "health";
      });
    },
    educationConsents() {
      if (!this.previewTerm || !this.previewTerm.consents) {
        return;
      }
      return this.previewTerm.consents.filter(function (c) {
        return c.type === "education";
      });
    },
    marketingConsents() {
      if (!this.previewTerm || !this.previewTerm.consents) {
        return;
      }
      return this.previewTerm.consents.filter(function (c) {
        return c.type === "marketing";
      });
    },
    otherConsents() {
      if (!this.previewTerm || !this.previewTerm.consents) {
        return;
      }
      return this.previewTerm.consents.filter(function (c) {
        return c.type === "other";
      });
    },
    lastPage() {
      if (this.otherConsents?.length > 0) {
        return "other";
      } else if (this.marketingConsents?.length > 0) {
        return "marketing";
      } else if (this.educationConsents?.length > 0) {
        return "education";
      } else if (this.healthConsents?.length > 0) {
        return "health";
      }
    },
  },
  components: {
    VueEditor,
  },
};
</script>