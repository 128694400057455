<template>
  <div>
    <form @submit.prevent="submitForm" v-if="terms">
      <div class="card border-0 bg-light shadow-sm mb-4">
        <div class="card-body">
          <h5>Clinic Terms Document</h5>

          <div class="row mb-3">
            <div class="col-6">
              <label>Name</label>
              <input
                type="text"
                class="form-control"
                placeholder="Document name"
                v-model="terms.title"
                required
              />
            </div>
            <div class="col-6">
              <label>Status</label>
              <select
                class="form-control"
                v-model="terms.status"
                required
              >
                <option value="draft">Draft</option>
                <option value="published">Published</option>
                <option value="published-snippet-only">Published for Snippet Only</option>
              </select>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <label>Terms Body (Overall Statement)</label>
              <vue-editor
                v-model="terms.body"
                class="bg-white"
              ></vue-editor>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col">
              <h5>Health Care Permissions</h5>
              <div class="row mb-3">
                <div class="col">
                  <input
                    type="text"
                    v-model="newHealthConsent"
                    class="form-control form-control-sm"
                    placeholder="Add a new checkbox..."
                  />
                </div>
                <div class="col-auto">
                  <button
                    class="float-end btn btn-sm btn-outline-primary"
                    @click.prevent="addConsent(newHealthConsent, 'health')"
                  >
                    <i class="far fa-plus me-1"></i>
                    Add Checkbox
                  </button>
                </div>
              </div>

              <draggable
                class="list-group list-group-flush mt-3"
                tag="div"
                handle=".handle"
                v-model="terms.consents"
                v-bind="dragOptions"
                v-if="terms.consents && terms.consents.length"
              >
                <div
                  class="card mb-3"
                  v-bind:key="consent.id"
                  v-for="consent in terms.consents.filter((el) => {
                    return el.type === 'health';
                  })"
                >
                  <consent-row
                    :consent="consent"
                    @updateMandatoryState="updateMandatoryState"
                    @removeConsent="removeConsent"
                  />
                </div>
              </draggable>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <h5>Educational Preferences</h5>
              <div class="row mb-3">
                <div class="col">
                  <input
                    type="text"
                    v-model="newEducationConsent"
                    class="form-control form-control-sm"
                    placeholder="Add a new  checkbox..."
                  />
                </div>
                <div class="col-auto">
                  <button
                    class="float-end btn btn-sm btn-outline-primary"
                    @click.prevent="
                      addConsent(newEducationConsent, 'education')
                    "
                  >
                    <i class="far fa-plus me-1"></i>
                    Add Checkbox
                  </button>
                </div>
              </div>

              <draggable
                class="list-group list-group-flush mt-3"
                tag="div"
                handle=".handle"
                v-model="terms.consents"
                v-bind="dragOptions"
                v-if="terms.consents && terms.consents.length"
              >
                <div
                  class="card mb-3"
                  v-bind:key="consent.id"
                  v-for="consent in terms.consents.filter((el) => {
                    return el.type === 'education';
                  })"
                >
                  <consent-row
                    :consent="consent"
                    @updateMandatoryState="updateMandatoryState"
                    @removeConsent="removeConsent"
                  />
                </div>
              </draggable>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <h5>Marketing Preferences</h5>
              <div class="row mb-3">
                <div class="col">
                  <input
                    type="text"
                    v-model="newMarketingConsent"
                    class="form-control form-control-sm"
                    placeholder="Add a new checkbox..."
                  />
                </div>
                <div class="col-auto">
                  <button
                    class="float-end btn btn-sm btn-outline-primary"
                    @click.prevent="
                      addConsent(newMarketingConsent, 'marketing')
                    "
                  >
                    <i class="far fa-plus me-1"></i>
                    Add Checkbox
                  </button>
                </div>
              </div>

              <draggable
                class="list-group list-group-flush mt-3"
                tag="div"
                handle=".handle"
                v-model="terms.consents"
                v-bind="dragOptions"
                v-if="terms.consents && terms.consents.length"
              >
                <div
                  class="card mb-3"
                  v-bind:key="consent.id"
                  v-for="consent in terms.consents.filter((el) => {
                    return el.type === 'marketing';
                  })"
                >
                  <consent-row
                    :consent="consent"
                    @updateMandatoryState="updateMandatoryState"
                    @removeConsent="removeConsent"
                  />
                </div>
              </draggable>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col">
              <h5>Other</h5>
              <div class="row mb-3">
                <div class="col">
                  <input
                    type="text"
                    v-model="newOtherConsent"
                    class="form-control form-control-sm"
                    placeholder="Add a new checkbox..."
                  />
                </div>
                <div class="col-auto">
                  <button
                    class="float-end btn btn-sm btn-outline-primary"
                    @click.prevent="addConsent(newOtherConsent, 'other')"
                  >
                    <i class="far fa-plus me-1"></i>
                    Add Checkbox
                  </button>
                </div>
              </div>

              <draggable
                class="list-group list-group-flush mt-3"
                tag="div"
                handle=".handle"
                v-model="terms.consents"
                v-bind="dragOptions"
                v-if="terms.consents && terms.consents.length"
              >
                <div
                  class="card mb-3"
                  v-bind:key="consent.id"
                  v-for="consent in terms.consents.filter((el) => {
                    return el.type === 'other';
                  })"
                >
                  <consent-row
                    :consent="consent"
                    @updateMandatoryState="updateMandatoryState"
                    @removeConsent="removeConsent"
                  />
                </div>
              </draggable>
            </div>
          </div>

          <div class="row">
            <div class="col-auto ms-auto">
              <button class="btn btn-dark me-2" @click.prevent="closeBuilder">
                <i class="fa fa-times me-1"></i>
                Cancel
              </button>
              <button
                class="btn btn-outline-primary me-2"
                @click.prevent="previewTemplate"
              >
                <i class="fa fa-eye me-1"></i>
                Preview
              </button>
              <button class="btn btn-primary" :disabled="busySaving">
                <busy :visible="busySaving" button>Saving...</busy>
                <span v-if="!busySaving">
                  <i class="fa fa-save me-1"></i>
                  Save Clinic Terms
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <preview-modal
      name="previewTermsModal"
      v-if="terms"
      :preview-term="terms"
    ></preview-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { VueEditor } from "vue2-editor";
import PreviewModal from "./PreviewModal";
import ConsentRow from "./partials/ConsentRow";

export default {
  props: ["user", "clinicTerms"],
  data() {
    return {
      terms: [],
      busySaving: false,
      newHealthConsent: "",
      newEducationConsent: "",
      newMarketingConsent: "",
      newOtherConsent: "",
    };
  },

  methods: {
    updateMandatoryState(consent) {
      if (!consent.title) {
        return;
      }
      this.terms.consents = this.terms.consents.map((c) => {
        if (c.title === consent.title) {
          c.mandatory = !c.mandatory;
        }
        return c;
      });
    },
    submitForm() {
      if (this.terms.consents.length > 0) {
        if (this.terms.status === "published"
          || this.terms.status === "published-snippet-only"
        ) {
          var confirmed = confirm(
            "You are about to publish these terms. " +
              "You will no longer be able to modify its contents or consents after publishing. " +
              "Are you sure?"
          );
          if (confirmed) {
            this.performSubmitForm();
          }
        } else if (this.terms.status === "discontinued") {
          var confirmed = confirm(
            "You are about to discontinue these terms. " +
              "You will no longer be able to issue these terms once discontinued. " +
              "Are you sure?"
          );
          if (confirmed) {
            this.performSubmitForm();
          }
        } else {
          this.performSubmitForm();
        }
      } else {
        alert("At least one consent is required. Please add a checkbox.");
      }
    },
    performSubmitForm() {
      if (this.terms.id) {
        this.updateTerms();
      } else {
        this.createTerms();
      }
    },
    createTerms() {
      if (this.terms.consents.length > 0) {
        this.busySaving = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL + "/settings/terms",
            this.terms
          )
          .then(({ data }) => {
            this.busySaving = false;
            this.$emit("saved");
            this.$EventBus.$emit("alert", data);
          });
      } else {
        alert("Please add at least one agreement checkbox to the terms.");
      }
    },
    updateTerms() {
      if (this.terms.consents && this.terms.consents.length > 0) {
        this.busySaving = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/terms/" +
              this.terms.id,
            this.terms
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("saved");
            this.busySaving = false;
          });
      } else {
        alert("Please add at least one consent checkbox to the terms.");
      }
    },
    addConsent(title, type) {
      if (title) {
        this.terms.consents.push({
          localId: `consentId${this.terms.consents.length + 1}-${type}`,
          title: title,
          type: type,
          mandatory: true,
        });
        this.newHealthConsent = "";
        this.newEducationConsent = "";
        this.newMarketingConsent = "";
        this.newOtherConsent = "";
      }
    },
    removeConsent(consent) {
      this.terms.consents = this.terms.consents.filter(function (
        c
      ) {
        return c.title !== consent.title;
      });
    },
    previewTemplate() {
      $("#previewTermsModal").modal("show");
    },
    getDefaultTerms() {
      return {
        clinic_id: this.user.clinic_id,
        status: "draft",
        title: null,
        body: null,
        global: false,
        consents: [],
      };
    },
    closeBuilder() {
      this.$emit("cancel");
    },
  },
  mounted() {
    if (!this.clinicTerms || this.clinicTerms.length < 1) {
      this.terms = this.getDefaultTerms();
    } else {
      this.terms = this.clinicTerms;
    }
  },
  created() {},
  computed: {
    dragOptions() {
      return {
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  filters: {},
  components: {
    PreviewModal,
    VueEditor,
    ConsentRow,
    draggable
  },
};
</script>
