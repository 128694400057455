<template>
  <div>
    <div
      class="modal fade"
      :class="selectedTerm ? 'show' : ''"
      tabindex="-1"
      role="dialog"
      id="shareTemplateModal"
    >
      <div
        class="modal-dialog modal modal-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content bg-white">
          <div class="modal-body">
            <div class="row">
              <div class="col">
                <p class="alert alert-danger my-2 mb-3">
                  <i class="far fa-info me-2"></i>
                  Sharing with all new clients means that all new clients added
                  to your clinic will automatically receive this terms document
                  to accept within their client portal.
                </p>
                <div class="form-check my-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="shareWithAll"
                    id="shareWithAllChbId"
                  />
                  <label
                    for="shareWithAllChbId"
                    class="form-check-label"
                  >
                    Share with <b>all new clients</b> automatically
                  </label>
                </div>

                <p class="alert alert-danger my-2 mb-3">
                  <i class="far fa-info me-2"></i>
                  Sharing with all existing clients will share this terms
                  document with all of your existing clients who have not
                  already received it.
                  <br /><br />
                  To share the agreement with an individual client instead, you
                  can do this from their client profile.
                </p>
                <button
                  class="btn btn-outline-primary mb-3"
                  @click="shareWithExistingClients"
                  :disabled="busySharing"
                >
                  <i class="far fa-share-all me-1"></i>
                  Share with existing clients
                </button>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <button
                  type="button"
                  class="btn btn-light me-auto"
                  data-bs-dismiss="modal" data-dismiss="modal"
                  aria-label="Close"
                  @click="cancelShare()"
                >
                  Cancel
                </button>
                <button
                  class="btn btn-primary float-end"
                  :disabled="busySaving"
                  @click="updateShare"
                >
                  <busy :visible="busySaving">Saving...</busy>
                  <span v-if="!busySaving">
                    <i class="fa fa-save me-1"></i>
                    Save
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedTerm"],
  data() {
    return {
      busySaving: false,
      busySharing: false,
      name: "",
      shareWithAll: !this.selectedTerm ? null : this.selectedTerm.share_with_all,
    };
  },
  watch: {
    selectedTerm(newValue) {
      if (newValue && newValue.id) {
        $("#shareTemplateModal").modal("show");
      } else {
        $("#shareTemplateModal").modal("hide");
      }
    }
  },
  methods: {
    updateShare() {
      this.busySaving = true;
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/terms/" +
            this.selectedTerm.id,
          {
            share_with_all: this.shareWithAll,
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.busySaving = false;
          this.$emit("updated");
          this.cancelShare();
        });
    },
    shareWithExistingClients() {
      var confirmed = confirm(
        "This will share the terms document to ALL of your existing clients who have not received this document already. Are you sure?"
      );
      if (confirmed) {
        this.busySharing = true;
        this.$axios
          .post(
            process.env.VUE_APP_API_URL +
              "/settings/terms/" +
              this.selectedTerm.id +
              "/share-with-existing"
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
            this.$emit("updated");
            this.busySharing = false;
          });
      }
    },
    cancelShare() {
      this.$emit("close");
      $("#shareTemplateModal").modal("hide");
    },
  }
};
</script>