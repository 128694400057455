<template>
  <div class="card-body p-2 px-3">
    <div class="row">
      <div class="col-auto">
        <i
          class="fa fa-arrows-alt handle"
          style="cursor: move"
        ></i>
      </div>
      <div class="col">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" />
          <label class="form-check-label">
            {{ consent.title }}
          </label>
          <i
            class="far fa-trash me-1 float-end cursor-pointer"
            @click="removeConsent(consent)"
          />
          <div
            class="custom-control custom-switch float-end me-2"
            style=""
          >
            <input
              type="checkbox"
              class="custom-control-input"
              :id="`enableBooking${elId}`"
              :checked="consent.mandatory"
              @change="updateMandatoryState(consent)"
            />
            <label
              class="custom-control-label"
              :for="`enableBooking${elId}`"
            >
              Mandatory
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["consent"],
  data() {
    return {
    };
  },
  computed: {
    elId() {
      return this.consent.localId ? this.consent.localId : this.consent.id
    }
  },
  methods: {
    updateMandatoryState(consent) {
      this.$emit('updateMandatoryState', consent);
    },
    removeConsent(consent) {
      this.$emit('removeConsent', consent);
    },
  }
}
</script>
